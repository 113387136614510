<template>
  <div>
    <el-row v-if="!pageStatus.error" v-loading="loading">
      <el-col :span="20" :offset="2">
        <!-- Бүх хуудсанд ашиглагдах header section -->
        <custom-header :title="'Брэнд засварлах'"/>
        <div class="form-error-container mb20" v-if="errorArray.length != 0">
          <header>Уучлаарай {{errorArray.length}} зүйл алдаатай байна</header>
          <ul class="error-list">
            <div v-for="(error, index) in errorArray" :key="index">
              <li>{{error.value}}</li>
            </div>
          </ul>
        </div>
        <el-form label-position="top" :model="brandData" :rules="brandRules" ref="brandData">
          <el-row :gutter="20">
            <el-col :span="16">
              <div class="panel">
                <div class="panel-item">
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item label="Монгол нэр" prop="brand_monName">
                        <el-input v-model="brandData.brand_monName" placeholder="Говь ХХК ..."></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="Англи нэр" prop="brand_engName">
                        <el-input v-model="brandData.brand_engName" placeholder="Gobi LLC ..."></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item label="Нийлүүлэгч" prop="supplier_id">
                        <el-select
                          disabled
                          v-model="brandData.supplier_mon_name"
                          filterable
                          class="block">
                          <el-option
                            v-for="item in supplierOption"
                            :key="item.supplier_id"
                            :label="item.supplier_monName"
                            :value="item.supplier_id">
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="Шимтгэлийн хувь" prop="fee_percent">
                        <el-input v-model="brandData.fee_percent" disabled placeholder="Шимтгэлийн хувь"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item label="Төлөв">
                        <el-switch
                          style="display: block"
                          v-model="brandData.isActive"
                          active-color="#13ce66"
                          inactive-color="#ff4949"
                          active-text="Идэвхитэй"
                          inactive-text="Идэвхгүй"
                          class="mt10 text-center">
                        </el-switch>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="Нийлүүлэгчийн брэнд">
                        <el-input v-model="brandData.supplier_brand_id" placeholder="Нийлүүлэгчийн брэнд ID ..."></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="24">
                      <el-form-item label="Лого зураг">
                        <el-upload
                          list-type="picture-card"
                          accept=".jpg,.jpeg,.png,.JPG,.JPEG"
                          action=""
                          :limit="1"
                          :on-preview="handlePictureCardPreview"
                          :file-list="tempImages"
                          :on-remove="handleRemove"
                          :auto-upload="false"
                          :on-change="getFile">
                          <span>250x250</span>
                        </el-upload>
                        <loader :active="isUploading" />
                      </el-form-item>
                    </el-col>
                    <el-col :span="24">
                      <el-form-item label="Тайлбар" prop="brand_description">
                        <el-input v-model="brandData.brand_description" placeholder="Улс, түүх"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="panel">
                <div class="panel-item">
                  <header>Брэндийн нийлүүлэгч</header>
                  <el-table
                    :data="brandData.supplier"
                    @cell-click="clickCell">
                    <el-table-column label="Нийлүүлэгч" align="center">
                      <template slot-scope="scope">
                        {{scope.row.supplier_monName}}
                      </template>
                    </el-table-column>
                    <el-table-column label="Регистр" align="center">
                      <template slot-scope="scope">
                        {{scope.row.supplier_regno}}
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </div>
            </el-col>
          </el-row>
          <div class="border-top pt20">
            <el-row :gutter="20">
              <el-col :span="12">
                <el-button class="text-left" type="danger" @click="onChange('delete')">Устгах</el-button>
              </el-col>
              <el-col :span="12" class="text-right">
                <el-button type="default" @click="confirmLeaveForm = true">Болих</el-button>
                <el-button type="success" @click="onPressNewBrand('brandData', 'brandRules')"
                >Хадгалах</el-button>
              </el-col>
            </el-row>
          </div>
        </el-form>
      </el-col>
    </el-row>
    <!-- Confirm discard changes dialog -->
    <el-dialog
      title="Discard all unsaved changes"
      :visible.sync="confirmLeaveForm"
      width="50%"
    >
      <span
        >If you discard changes, you’ll delete any edits you made since you last
        saved.</span
      >
      <span slot="footer" class="dialog-footer">
        <el-button @click="confirmLeaveForm = false" class="mr10"
          >Continue editing</el-button
        >
        <router-link to="/brandList">
          <el-button type="danger" @click="confirmLeaveForm = false"
            >Discard changes</el-button
          >
        </router-link>
      </span>
    </el-dialog>
    <!-- delete -->
    <el-dialog
      :title="isChanging ? 'Брэнд шинэчлэх' : 'Брэнд устгах'"
      :visible.sync="confirmBrandDialog"
      width="50%"
    >
      <div>
        <strong>{{brandData.brand_monName}}</strong> брэндийг {{ isChanging ? 'шинэчлэхдээ' : 'устгахдаа'}} шалтгаанаа заавал бичнэ үү?
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="confirmBrandDialog = false" class="mr10"
          >Болих</el-button
        >
        <el-button  v-if="isChanging" :disabled="isDisabled" type="success" @click="onPressNewBrand('brandData', 'brandRules')">
          Хадгалах
        </el-button>
        <el-button v-else type="danger" :disabled="isDisabled" @click="deleteBrand('brandData', 'brandRules')">
          Устгах
        </el-button>
      </span>
      <el-row :gutter="20">
        <el-col :span="24" class="mt20">
          <el-form :model="brandData" :rules="brandRules" ref="brandData">
            <el-form-item prop="desc" label="Шалтгаан">
              <el-input v-model="brandData.desc" type="textarea" :rows="2" :placeholder="isChanging ? 'Шинэчилж буй шалтгаанаа бичнэ үү ...' : 'Устгаж буй шалтгаанаа бичнэ үү ...'"></el-input>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </el-dialog>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>

<script>
import services from '../../../helpers/services'
import customHeader from '../../../components/common/customHeader'
import { handlers } from '../../../helpers/custom'
import loader from '../../../components/common/loader'
export default {
  name: 'brandUpdateForm',
  components: {
    customHeader: customHeader,
    loader: loader
  },

  created () {
    if (this.$route.params.brand_id) {
      this.getBrandByBrandId(this.$route.params.brand_id)
    }
  },

  data () {
    return {
      tempImages: [],
      isUploading: false,
      isChanging: false,
      confirmBrandDialog: false,
      loading: false,
      confirmLeaveForm: false,
      dialogVisible: false,
      dialogImageUrl: '',
      isDisabled: false,
      supplierOption: [],
      brandList: [],
      supplierBrand: [],
      errorArray: [],
      pageStatus: {
        error: false,
        message: ''
      },
      brandData: {
        desc: '',
        brand_id: '',
        brand_monName: '',
        brand_engName: '',
        supplier_id: '',
        brand_description: '',
        brand_logo: '',
        supplier_brand_id: ''
      },
      brandRules: {
        desc: [
          { required: true, message: 'Заавал бөглөнө үү?', trigger: 'blur' }
        ],
        brand_monName: [
          { required: true, message: 'Брэндийн монгол нэрээ оруулна уу', trigger: 'blur' }
        ],
        supplier_id: [
          { required: true, message: 'Нийлүүлэгчээ сонгоно уу', trigger: 'blur' }
        ],
        brand_engName: [
          { required: true, message: 'Брэндийн англи нэрээ оруулна уу', trigger: 'blur' }
        ],
        brand_description: [
          { required: true, message: 'Брэндийн тайлбараа оруулна уу', trigger: 'blur' }
        ]
      }
    }
  },

  methods: {
    onChange (type) {
      if (type === 'update') {
        this.confirmBrandDialog = true
        this.isChanging = true
      } else {
        this.confirmBrandDialog = true
        this.isChanging = false
      }
    },
    clickCell (row, column, cell, event) {
      this.$router.push(
        {
          name: 'supplierEdit',
          params: {
            supplier_id: row.supplier_id
          }
        }
      )
    },
    handlePictureCardPreview (file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    getFile (file, type) {
      handlers.checkImageSize(file, true, 250, 250).then(() => {
        this.isUploading = true
        handlers.getBase64(file.raw).then(res => {
          const tempBody = {
            image: res,
            filePath: file.name
          }
          services.imageUpload(tempBody).then(response => {
            this.isUploading = false
            if (response.status === 'success') {
              this.brandData.brand_logo = response.url
              if (type === 'image') {
                this.tempImages.push({
                  url: response.url
                })
              }
            } else {
              this.$message.error('Уучлаарай алдаа гарлаа!')
            }
          })
        })
      }).catch((error) => {
        this.handleRemove(file, type)
        this.$message.error(error.message)
      })
    },
    // getFile (file) {
    //   const isLt2M = file.size / 1024 / 1024 < 1
    //   if (!isLt2M) {
    //     this.$message.error('Зурагны хэмжээ ихдээ 1MB байх ёстой!')
    //     this.imageNull = 'none'
    //   } else {
    //     this.isUploading = true
    //     handlers.getBase64(file.raw).then(res => {
    //       const tempBody = {
    //         image: res,
    //         filePath: file.name
    //       }
    //       services.imageUpload(tempBody).then(response => {
    //         this.isUploading = false
    //         if (response.status === 'success') {
    //         } else {
    //           this.$message.error('Уучлаарай алдаа гарлаа!')
    //         }
    //       })
    //     })
    //   }
    // },

    onPressNewBrand (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true
          if (this.brandData.brand_id !== '') {
            services.updateBrand(this.brandData).then(data => {
              this.alertReporter(data.status === 'success' ? 'Амжилттай' : 'Уучлаарай', data.message, data.status)
              if (data.status === 'success') {
                this.$refs[formName].resetFields()
                this.loading = false
                this.$router.push({ name: 'brandList' })
              } else if (data.status === 'error') {
                this.alertReporter('Алдаа', data.message, 'error')
                this.loading = false
              }
            })
          }
        }
      })
    },

    handleRemove (file) {
      var tempId = 0
      this.tempImages.forEach((element, index) => {
        if (element.uid === file.uid) {
          tempId = index
        }
      })
      this.tempImages.splice(tempId, 1)
    },
    getBrandByBrandId (id) {
      services.getBrandByBrandId(id).then(response => {
        if (response.status === 'success') {
          this.brandData = response.data
          this.loading = false
          this.setImages(this.brandData.brand_logo)
        } else if (response.status === 'error') {
          this.alertReporter('Алдаа', response.message, 'error')
          this.loading = false
        }
      })
    },

    setImages (images) {
      var tempImages = []
      if (images && Array.isArray(images)) {
        images.forEach(element => {
          tempImages.push({
            url: element
          })
        })
      } else {
        tempImages.push({
          url: images
        })
      }
      this.tempImages = tempImages
    },

    alertReporter (title, message, type) {
      this.$notify({
        title: title,
        message: message,
        type: type,
        position: 'bottom-left'
      })
    },
    deleteBrand (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true
          this.isDisabled = true
          services.deleteBrand({ brand_id: this.brandData.brand_id, desc: this.brandData.desc }).then(response => {
            if (response.status === 'success') {
              this.alertReporter('Амжилттай', `${this.brandData.brand_id} дугаартай брэнд устлаа`, 'success')
              this.loading = false
              this.isDisabled = false
              this.$router.push({
                name: 'brandList'
              })
            } else if (response.status === 'error') {
              this.alertReporter('Алдаа', response.message, 'error')
              this.loading = false
            } else {
              return false
            }
          })
        }
      })
    }
  }
}
</script>

<style></style>
